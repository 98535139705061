<template>
  <div class="create-project">
    <form @submit.prevent="createNewProject">
      <div class="form-group">
        <label for="name">Name:</label>
        <b-form-input id="name" v-model="project.name" required></b-form-input>
      </div>
      <div class="form-group">
        <label for="description">Description:</label>
        <b-form-textarea
          id="description"
          v-model="project.description"
          required
        ></b-form-textarea>
      </div>
      <div class="d-flex justify-content-space-between">
        <div class="form-group w-100 mr-2">
          <label for="type">Type:</label>
          <b-form-select id="type" v-model="project.type" required>
            <option disabled value="">Select Type</option>
            <option v-for="option in projectTypes" :key="option + '56'" :value="option.toLowerCase()">{{ option }}</option>
          </b-form-select>
        </div>
        <div class="form-group w-100">

          <label for="image_subtype">Image Subtype:</label>
          <b-form-select id="image_subtype" v-model="project.image_subtype">
            <option disabled value="">Select Image Subtype</option>
            <option value="x-ray">X-Ray</option>
            <option value="ultrasound">Ultrasound</option>
            <option value="mammography">Mammography</option>
            <option value="CT">CT</option>
            <option value="MRI">MRI</option>
          </b-form-select>
          <!-- <label for="source">Source:</label>
          <b-form-select id="source" v-model="project.source" required>
            <option disabled value="">Select Source</option>
            <option value="upload">Upload</option>
            <option value="mixed">Mixed</option>
            <option value="DICOM">DICOM</option>
            <option value="api">API</option>
          </b-form-select> -->
        </div>
      </div>
      <div class="form-group">

      <div class="d-flex justify-content-space-between">
        <div class="form-group w-100 mr-2">
          <label for="imageType">Image Type:</label>
          <b-form-select id="imageType" v-model="project.image_type">
            <option disabled value="">Select Image Type</option>
            <option value="single">Single Image</option>
            <option value="sets">Image Sets</option>
          </b-form-select>
        </div>
        <div class="form-group w-100">
          <label for="image_subtype">RATify Labeller Version:</label>
          <b-col>
            <!--  -->
            <b-row align-h="around">
            <b-form-radio v-model="project.version" value=1 :disabled="project.image_subtype === 'MRI'">v1 </b-form-radio>
            <b-form-radio v-model="project.version" value=2>v1.2</b-form-radio>
          </b-row>
          </b-col>
        </div>

      </div>

      <b-form-checkbox
          :disabled="!allowTraining"
          id="enable_training"
          v-model="project.enable_training"
        ><p class="pt-1">Allow AI model training</p></b-form-checkbox>
      </div>
      <b-button type="submit" :disabled="isLoading" pill  class="btn-yellow mb-4 px-5 create-button">
        <font-awesome-icon icon="plus" class="create-icon" />
        <span v-if="isLoading">
          <i class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></i>
        </span>
        Create Project
      </b-button>
    </form>
  </div>
</template>

<script>
import { BFormSelect, BFormInput, BFormTextarea } from 'bootstrap-vue'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    // BButton,
    BFormSelect,
    BFormInput,
    BFormTextarea
    // FontAwesomeIcon
  },

  data () {
    return {
      project: {
        name: '',
        description: '',
        type: '',
        source: 'upload',
        members: [],
        enable_training: false,
        image_type: '',
        image_subtype: '',
        version: 0
      },
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      auth: state => state.auth
    }),
    ...mapState('license', ['info']),
    projectTypes () {
      if (this.info && this.info.projectTypes) {
        return this.info.projectTypes
      } else {
        return []
      }
    },
    allowTraining () {
      if (this.info && this.info.training) {
        return this.info.training
      } else {
        return false
      }
    }
  },

  methods: {
    ...mapActions('projectSubmission', {
      createProject: 'createProject'
    }
    ),

    createNewProject () {
      this.isLoading = true

      this.project.members.push(this.auth.user.id)
      this.createProject(this.project).then(() => {
      // Reset the form data
        this.project = {
          name: '',
          description: '',
          type: '',
          source: '',
          members: [],
          enable_training: false,
          image_type: '',
          image_subtype: ''
        }

        this.isLoading = false
        this.$emit('project-created')
      })
        .catch(error => {
          console.error('An error occurred while creating the project:', error)
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
.create-project {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 10px;
}

label {
  display: block;
  font-weight: bold;
}

.create-button {
  background-color: #f0b93b!important;
  color: #14304c!important ;
  border: #f0b93b!important;
}
.main .accordion .card-header .btn:after {content:none;}

.create-icon {
  color: #14304c;
  margin-right: 5px;
}

button[type="submit"]:hover {
  background-color: #14304c;
  color: aliceblue !important;
  border: #14304c;
}

.form-inline-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.form-inline-group .form-group {
  margin-right: 10px;
}
</style>
