<template>
  <div class="p-4">
    <div
      v-if="membersNotInProject.length === 0 && !loading"
      class="text-center my-4"
    >
      <div>
        <b-icon icon="shield-check" variant="success" font-scale="4"></b-icon>
      </div>
      <div class="mt-2">
        <h5 class="color-yellow">All eligible members have been successfully added to the project.</h5>
      </div>
    </div>

    <div v-else>
      <b-form-input
        v-model="searchQuery"
        placeholder="Search users..."
        class="mb-3"
      ></b-form-input>

      <div class="d-flex justify-content-end" v-if="selectedUsers.length > 0">
        Selected: {{ selectedUsers.length }} / {{ membersNotInProject.length }}
      </div>

      <label>
        <span
          class="selected-users-label"
          v-for="(user, index) in selectedUsers"
          :key="index"
        >
          {{ user.item.personal.fullname }}
        </span>
      </label>

      <div class="table-container" :key="'member'+ rerender">
        <b-table
          :key="'member'+ rerender"
          :items="filteredUsers"
          :fields="tableFields"
          :row-class="(item) => (item.selected ? 'selected-row' : '')"
        >
          <template #cell(personal.fullname)="item">
            {{ item.item.personal.fullname }} <small> {{isLicensed(item) ? '(Licensed)' : '(Unlicensed)'}} </small>
          </template>
          <template #cell(selected)="item">
            <b-form-checkbox
              :disabled="(remainingUsers === 0 && !item.item.selected && !isLicensed(item))"
              :checked="item.item.selected"
              @change="toggleUserSelection(item)"
            ></b-form-checkbox>
          </template>
          <template #cell(role)="item">
            <b-form-select
              v-model="item.role"
              :options="roleOptions"
              :value="getRole(item)"
              @change="updateUserRole(item)"
            ></b-form-select>
          </template>
          <template #head(selected)>
            <span>Add Member</span>
          </template>
        </b-table>
      </div>

      <div v-if="loading" class="text-center my-4">
        <b-spinner></b-spinner>
        <div>Loading users...</div>
      </div>

      <div class="d-flex justify-content-end mt-4">
        <b-button
          class="bg-navy rounded-pill"
          @click="addMembers"
          :disabled="this.selectedUsers.length === 0"
        >
          Add Members(s)
        </b-button>
        <b-button
          variant="secondary"
          @click="closeModal"
          class="mx-1 rounded-pill"
          >Cancel</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      selectedRole: 'View Only',
      roleOptions: [
        { value: 'View Only', text: 'View Only' },
        { value: 'Administrator', text: 'Administrator' },
        { value: 'Reader', text: 'Reader' },
        { value: 'Moderator', text: 'Moderator' },
        { value: 'Data Loader', text: 'Data Loader' },
        { value: 'Data Scientist', text: 'Data Scientist' }
      ],

      searchQuery: '',
      selectedUsers: [],
      rerender: 0
    }
  },
  computed: {
    ...mapState('usersSubmission', ['loading', 'users', 'membersNotInProject', 'members']),
    ...mapState({
      selectedProjectId: (state) => state.projectSubmission.selectedProjectId,
      auth: (state) => state.auth
    }),
    ...mapState('license', ['info']),
    maxUsers () {
      if (this.info && this.info.availableUsers) {
        return this.info.availableUsers
      } else {
        return -1
      }
      // return 3
    },
    remainingUsers () {
      if (this.maxUsers === -1) {
        return -1
      } else {
        const tmp = this.selectedUsers.length
        return this.maxUsers - this.members.length - tmp
      }
    },
    filteredUsers () {
      const query = this.searchQuery.toLowerCase()
      const _tmp = this.membersNotInProject
        .map((user) => {
          const existingUser = this.selectedUsers.find(
            (selectedUser) => selectedUser.item._id === user._id
          )
          // let lic
          // if (this.info && this.info.licensedUserList && this.info.licensedUserList.length > 0) {
          //   lic = this.info.licensedUserList.findIndex(u => u._id === this.auth.user.id) >= 0
          // } else {
          //   lic = false
          // }
          const fullname = user.personal.fullname.toLowerCase()
          const email = user.personal.email.toLowerCase()
          const qualification = user.personal && user.personal.qualification ? user.personal.qualification.toLowerCase() : '-'
          const phone = user.personal && user.personal.phone ? user.personal.phone.toLowerCase() : '-'
          const companyName = user.company && user.company.name ? user.company.name.toLowerCase() : '-'
          const matchesQuery =
            fullname.includes(query) ||
            email.includes(query) ||
            qualification.includes(query) ||
            phone.includes(query) ||
            companyName.includes(query)
          return {
            ...user,
            selected: existingUser ? existingUser.selected : false,
            matchesQuery
          }
        })
        .filter((user) => user.matchesQuery || user.selected)
      // this.rerender +=

      console.log('filtered user', _tmp)
      return _tmp
    },
    tableFields () {
      return [
        {
          key: 'selected',
          label: '',
          class: 'text-center',
          thClass: 'text-center'
        },
        { key: 'personal.fullname', label: 'Full Name' },
        { key: 'personal.qualification', label: 'Qualification' },
        { key: 'personal.email', label: 'Email' },
        { key: 'personal.phone', label: 'Phone' },
        { key: 'company.name', label: 'Company' },
        {
          key: 'role',
          label: 'Role',
          default: 'View Only'
        }
      ]
    },
    serach_change () {
      return this.searchQuery
    }
  },
  watch: {
    serach_change (_new, _old) {
      if (_new !== _old) {
        this.rerender += 1
      }
    }
  },
  methods: {
    isLicensed (item) {
      let lic
      if (this.info && this.info.licensedUserList && this.info.licensedUserList.length > 0) {
        lic = this.info.licensedUserList.findIndex(u => u._id === item.item._id) >= 0
      } else {
        lic = false
      }
      return lic
    },
    getRole (item) {
      // console.log('223', item.item._id)
      const userIndex = this.selectedUsers.findIndex(u => u.item._id === item.item._id)
      // console.log('223', userIndex)
      if (userIndex >= 0) {
        return this.selectedUsers[userIndex].role
      } else return 'View Only'
    },
    getSelected (item) {
      // console.log('add member', item)
      return item.selected ? item.selected : false
    },
    getFieldDefaultValue (item, key) {
      const field = this.tableFields.find((field) => field.key === key)
      return field ? field.default : null
    },
    ...mapActions('usersSubmission', [
      'getAll',
      'addMember',
      'getMembers',
      'getMembersNotInProject'
    ]),
    closeModal () {
      this.$emit('close-add-member')
    },
    toggleUserSelection (user) {
      const existingUserIndex = this.selectedUsers.findIndex(
        (selectedUser) => selectedUser.item._id === user.item._id
      )
      console.log('add member', existingUserIndex)
      console.log('add member', this.selectedUsers)
      if (existingUserIndex === -1) {
        this.selectedUsers.push({ ...user, role: 'View Only', selected: true })
      } else {
        this.selectedUsers.splice(existingUserIndex, 1)
      }
      // this.rerender += 1
    },

    updateUserRole (user) {
      const selectedUser = this.selectedUsers.find(
        (selectedUser) => selectedUser.item._id === user.item._id
      )
      if (selectedUser) {
        if (
          selectedUser.role !== user.role ||
          typeof selectedUser.role === 'undefined'
        ) {
          selectedUser.role = user.role
        }
      }
      // this.rerender += 1
    },

    async addMembers () {
      const membersWithRoles = this.selectedUsers.map((selectedUser) => ({
        members: selectedUser.item._id,
        role: selectedUser.role
      }))
      const projectId = this.selectedProjectId
      await this.addMember({ projectId, membersWithRoles })
      await this.getMembers(projectId)
      this.$emit('close-add-member')
    }
  },
  mounted () {
    this.getMembersNotInProject(this.selectedProjectId)
  }
}
</script>
<style scoped>
.selected-users-label {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 5px;
  background-color: #f0b93b;
  color: rgb(54, 54, 54);
  border-color: #f0b93b;
  font-weight: 400;
}

.selected-users-chip {
  margin-right: 5px;
}
</style>
